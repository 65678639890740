/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react"
import Container from "react-bootstrap/Container"
import { useTranslation, Trans } from "react-i18next"

import i18next from "../i18n/config"

/* Images */
import NavBarLogo from "../images/logo.svg"

/* Components */
import Wrapper from "../components/wrapper/wrapper"
import SEO from "../components/seo/seo"
import NavBar from "../components/navbar/navbar"
import Main from "../components/main/main"
import ContactBox from "../components/contact-box/contact-box"
import Hr from "../components/hr/hr"
import Faqs from "../components/faqs/faqs"
import FaqsItem from "../components/faqs-item/faqs-item"
import Footer from "../components/footer/footer"

import { beaconProfile, beaconData, parseQueryStrings } from "../utils/beacon"

export default function Ayuda({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.help.title")

  const ContactTitle = t("pages.help.contact.title")
  const ContactText = t("pages.help.contact.text")
  const ContactTextKey = "pages.help.contact.text"
  const ContactButtonText = t("pages.help.contact.buttonText")

  const FaqsTitle = t("pages.help.questions.title")
  const FaqsItems = t("pages.help.questions.items")

  const RenderFaqsItems = []

  for (const [index, faq] of FaqsItems.entries()) {
    const i18nKey = `pages.help.questions.items.${index}.answer`
    RenderFaqsItems.push(
      <FaqsItem
        key={index}
        index={index}
        classVariant="faqsItemSimple"
        title={faq.title}
      >
        <Trans i18n={i18next} i18nKey={i18nKey}>
          {faq.answer}
          <a>.</a>
        </Trans>
      </FaqsItem>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    /* Data Params */
    if (window.location.search) {
      const locationMeta = parseQueryStrings(window.location.search)

      const metaProfile = new beaconProfile(locationMeta)
      const metaData = new beaconData(locationMeta)

      // Set Beacon Profile
      window.Beacon("identify", metaProfile)
      // Set Beacon Form Prefill
      window.Beacon("prefill", metaProfile)

      // Set Season Data (Brand/OS/Refer/Device/Locale/IDs)
      window.Beacon("session-data", metaData)
    }
  }, [])

  return (
    <Wrapper>
      <SEO title={PageTitle} />
      <NavBar classVariant="navBarDefault" logo={NavBarLogo} />
      <Main>
        <Container>
          <ContactBox title={ContactTitle} buttonText={ContactButtonText}>
            <Trans i18n={i18next} i18nKey={ContactTextKey}>
              {ContactText}
              <a>.</a>
            </Trans>
          </ContactBox>
          {FaqsTitle && <Hr />}
          <Faqs title={FaqsTitle} classVariant="faqsWrapperLarge" id="faq">
            {RenderFaqsItems}
          </Faqs>
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  )
}
